.App {
  padding: 0 !important;
  margin: 0 !important;
  height: 100vh;
  width: 100%;
  .navbar-part {
    position: absolute;
    top: 20px;
    z-index: 1000;
    height: 50px;
    width: 100% !important;
    display: flex;
    justify-content: center;
    align-items: flex-end;
    background-color: transparent;
  }
  .content-part {
    padding: 70px 5% 10px 5%;
    height: 100%;
    width: 100%;
    overflow: auto;
  }
}

